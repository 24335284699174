import { withZod } from '@remix-validated-form/with-zod'
import { z } from 'zod'

const loginFormSchema = z.object({
  employeeCode: z
    .string()
    .length(4, '従業員番号は4文字で入力してください')
    .regex(/^\d{4}$/, '従業員番号は4桁の数字のみで入力してください。'),
  password: z
    .string()
    .min(8, 'パスワードは8文字以上で入力してください')
    .max(128, 'パスワードは128文字以下で入力してください')
    .refine(
      (password: string) => /[A-Za-z]/.test(password) && /[0-9]/.test(password),
      'パスワードは半角英数字の両方を含めてください',
    ),
})

export const loginValidator = withZod(loginFormSchema)
